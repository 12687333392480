<div class="wrapper">
  <h1 id="wrapper">How do you want to join your Airglass call?</h1>
  <div class="w-50">
    <div class="block w-100">
      <div class="text">
        <p>Download Airglass <span>Use the desktop app for the best experience.</span></p>
      </div>
      <button (click)="download()">
        Download now
      </button>
    </div>
    <div class="block w-100">
      <div class="text">
        <p>Continue on this browser <span>No download or installation required.</span></p>
      </div>
      <button (click)="openBrowser()">
        Join via browser
      </button>
    </div>
    <div class="block w-100">
      <div class="text">
        <p>Open Airglass<span>Already have it? Go right to your call.</span></p>
      </div>
      <button (click)="openApp()">
        Open Airglass
      </button>
    </div>
  </div>
  <div class="powered">
    <span><img src="https://airglass-assets.mobeus.com/hotlink-ok/static_content/assets/images/global/butterfly.svg" />Powered by Mobeus </span>
  </div>
</div>
