<div class="header" [class.show-menu]="showMenu">
  <div class="header-glow"></div>
  <div class="header-lines">
    <span class="header-lines__item"></span>
    <span class="header-lines__item"></span>
    <span class="header-lines__item"></span>
    <span class="header-lines__item"></span>
  </div>
  <div class="header__frame container">
    <a href="javascript:void(0)" (click)="toggleMenu()" class="toggle-menu">
      <mb-icon-simple icon="icon-menu-2"></mb-icon-simple>
    </a>

    <a class="header__logo" href="/"><img src="/assets/images/butterfly-logo.svg" width="48" height="36" alt=""></a>

    <div class="navigation__wrap">
      <a href="javascript:void(0)" (click)="toggleMenu()" class="toggle-menu">
        <mb-icon-simple icon="icon-close"></mb-icon-simple>
      </a>

      <ul class="navigation">
        <li><a target="_blank" href="{{companyRedirectUrl}}/home">Company</a></li>
        <li><a target="_blank" href="https://blog.mobeus.com/">Blog</a></li>
        <li><img src="/assets/images/butterfly-logo.svg" width="40" height="28" alt=""></li>
        <li><a target="_blank" href="https://help.mobeus.com/hc/en-us">Help</a></li>
        <li><a target="_blank" href="https://airglass.mobeus.com">Get Airglass</a></li>
        <!-- <li><a href="#">Login</a></li> -->
        <!-- <li><a target="_blank" href="#">Download Airglass</a></li> -->
      </ul>
    </div>
    <div class="navigation__overlay"></div>
  </div>
</div>