<div class="demo">
  <div class="demo__video-wrap">
    <div class="demo__video-holder">
      <video
        id="demoVideo"
        class="demo__video"
        autoplay
        muted
        src="../../../assets/video/demo.mp4"
      >
      </video>
    </div>
  </div>
  <div class="demo__wrap">
    <div class="demo__list">
      <div class="demo__item">
        <div class="demo__item-info">
          <h2 class="demo__item-title">Easily share and collaborate in any meeting app</h2>
        </div>
        <div class="demo__item-media">
          <div class="demo__item-thumb">
            <img src="/assets/images/demo/easy-share-2.webp" alt="">
            <div class="demo__share-notes">
              <div class="note">
                <div class="note__nb">1</div>
                <div class="note__content">Start your Zoom or Teams meeting</div>
              </div>
              <div class="note">
                <div class="note__nb">2</div>
                <div class="note__content">Share your desktop</div>
              </div>
              <div class="note">
                <div class="note__nb">3</div>
                <div class="note__content">
                  Activate Airglass to present in your content by yourself or with other people
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="demo__item">
        <div class="demo__item-info">
          <h2 class="demo__item-title">Connect with anyone else on Airglass</h2>
        </div>
        <div class="demo__item-media">
          <div class="demo__item-thumb">
            <div class="connect-with-thumb">
              <img class="connect-with-thumb__back" src="/assets/images/demo/connect-with-back.webp" alt="">
              <img class="connect-with-thumb__front" src="/assets/images/demo/connect-with-front.webp" alt="">
            </div>
            <div class="note demo__note-connect demo__note-connect--1">
              <div class="note__content">
                Just type their name, click <br>Add then invite them to a call
              </div>
              <span class="note__arrow note__arrow--right note__arrow-connect--1"></span>
            </div>
            <div class="note demo__note-connect demo__note-connect--2">
              <div class="note__content">
                See who is online or offline <br>by their avatar color
              </div>
              <span class="note__arrow note__arrow--left reverse note__arrow-connect--2"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="demo__item">
        <div class="demo__item-info">
          <h2 class="demo__item-title">Control your <br>viewer’s attention</h2>
        </div>
        <div class="demo__item-media">
          <div class="demo__item-thumb">
            <img src="/assets/images/demo/control-viewers.webp" alt="">
            <div class="note demo__note-control demo__note-control--1">
              <div class="note__icons note__icons--before">
                <mb-icon icon="icon-share-on" class="mb-icon-size-lg mb-icon-bg-1 mb-icon-purple"></mb-icon>
              </div>
              <div class="note__content">Start sharing your slides</div>
              <span class="note__arrow note__arrow--right note__arrow-control--1"></span>
            </div>
            <div class="note demo__note-control demo__note-control--2">
              <div class="note__content">Turn on Holograms or Teleports <br>so you can be IN your slides</div>
              <div class="note__icons note__icons--after note__icons--multi">
                <mb-icon icon="icon-video-tele" class="mb-icon-size-lg mb-icon-bg-2 mb-icon-green"></mb-icon>
                <mb-icon icon="icon-video-port" class="mb-icon-size-lg mb-icon-bg-3 mb-icon-green"></mb-icon>
              </div>
              <span class="note__arrow note__arrow--bottom-left note__arrow-control--2"></span>
            </div>
            <div class="note demo__note-control demo__note-control--3">
              <div class="note__icons note__icons--before">
                <mb-icon icon="icon-draw-2" class="mb-icon-size-lg mb-icon-bg-4 mb-icon-green"></mb-icon>
              </div>
              <div class="note__content">Draw to highlight areas</div>
              <span class="note__arrow note__arrow--top-straight note__arrow-control--3"></span>
            </div>
            <div class="note demo__note-control demo__note-control--4">
              <div class="note__content">Hotspot key areas to draw focus <br>to the most important areas</div>
              <div class="note__icons note__icons--after">
                <mb-icon icon="icon-hand" class="mb-icon-size-lg mb-icon-bg-5 mb-icon-green"></mb-icon>
              </div>
              <span class="note__arrow note__arrow--top-left note__arrow-control--4"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
