import { Component } from '@angular/core';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

@Component({
  selector: 'app-demo-features',
  templateUrl: './demo-features.component.html',
  styleUrls: ['./demo-features.component.scss']
})
export class DemoFeaturesComponent {
  ngOnInit() {
    gsap.registerPlugin(ScrollTrigger);
    console.log('gsap', gsap);

    const bgVideo = document.querySelector("#demoVideo");
    if (bgVideo) {
      const currentTime = 1;
    }

    gsap.to("#demoVideo", {
        currentTime: 4,
        scrollTrigger: {
            trigger: "#demoVideo",
            start: '100% 100%', // trigger viewport
            end: '100% top',
            markers: false,
            pin: false,
            scrub: 0.5,
            toggleActions: "restart pause resume pause"
        }
    });
  }

}
