import { Component } from '@angular/core';

@Component({
  selector: 'app-airglass',
  templateUrl: './airglass.component.html',
  styleUrls: ['./airglass.component.scss']
})
export class AirglassComponent {
  title = 'current-directory';
}
