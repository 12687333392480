import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  showMenu: boolean = false;
  companyRedirectUrl = environment.companyRedirectUrl;

  toggleMenu() : void {
    this.showMenu = !this.showMenu;
  }
}
