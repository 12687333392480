<div class="socials">
  <a  href="mailto:press@mobeus.com" target="_blank" rel="nofollow" class="socials__item">
    <mb-icon-simple icon="icon-envelope"></mb-icon-simple>
  </a>
  <a href="https://www.linkedin.com/company/mobeus" target="_blank" rel="nofollow" class="socials__item">
    <mb-icon-simple icon="icon-linkedin"></mb-icon-simple>
  </a>
  <a href="https://twitter.com/MobeusHQ" target="_blank" rel="nofollow" class="socials__item">
    <mb-icon-simple icon="icon-twitter"></mb-icon-simple>
  </a>
  <a href="https://www.youtube.com/channel/UC6bsVVQw-8qiZ6ULHWWO6VA" target="_blank" rel="nofollow" class="socials__item">
    <mb-icon-simple icon="icon-youtube"></mb-icon-simple>
  </a>
</div>
