<div class="gallery__section">
  <div class="gallery__inner">
    <swiper
      class="gallery__slider"
      [config]="config"
      #swiperSlideShow
    >
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/hero-slide-1.jpg" alt="">
            <div class="note hero gallery__note gallery__note--pos-1">
              <div class="note__content"> <div class="note__logo"> <a href="https://www.forbes.com/sites/johnkoetsier/2022/12/23/aerospace-giant-canceled-40000-unit-oculus-quest-order-when-they-saw-this-tech/?sh=4b53de7c7fbe" target="_blank"><img src="../../../assets/images/slider/forbes-logo.svg" alt=""></a></div> Aerospace Giant Cancelled 40,000-Unit Oculus Quest Order When They Saw This Tech</div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/hero-slide-2.jpg" alt="">
            <div class="note hero gallery__note gallery__note--pos-2">
              <div class="note__content"><div class="note__logo"> <a href="https://www.digitaltrends.com/computing/forget-vr-airglass-made-me-actually-enjoy-video-calls/" target="_blank"> <img src="../../../assets/images/slider/digital-logo.svg" alt=""></a></div>Forget VR. Airglass Made Me Actually Enjoy Video Calls</div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/hero-slide-3.jpg" alt="">
            <div class="note hero gallery__note gallery__note--pos-3">
              <div class="note__content"><div class="note__logo"> <a href="https://www.forbes.com/sites/johnkoetsier/2022/12/23/aerospace-giant-canceled-40000-unit-oculus-quest-order-when-they-saw-this-tech/?sh=4b53de7c7fbe" target="_blank"><img src="../../../assets/images/slider/forbes-logo.svg" alt=""></a></div>Get Ready to Hear More About Mobeus</div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/hero-slide-4.jpg" alt="">
            <div class="note hero gallery__note gallery__note--pos-1">
              <div class="note__content"><div class="note__logo"> <a href="https://njtechweekly.com/mobeus-cofounder-richie-etwaru-talks-about-innovation-telepresence-and-building-a-technology-from-scratch/" target="_blank"> <img src="../../../assets/images/slider/njtech-logo.png" alt=""></a></div>Title: Riche Etwaru talks about innovation, telepresence and building a technology from scratch</div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
