<div class="gallery__section">
  <div class="gallery__inner">
    <swiper
      class="gallery__slider"
      [config]="config"
      #swiperSlideShow
    >
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/slide-1.webp" alt="">
            <div class="note gallery__note gallery__note--pos-1">
              <div class="note__content">Point and spotlight key areas</div>
              <span class="note__arrow note__arrow--right"></span>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/slide-2.webp" alt="">
            <div class="note gallery__note gallery__note--pos-2">
              <div class="note__content">Draw in the air with your fingers!</div>
              <span class="note__arrow note__arrow--left note__arrow--lg"></span>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="gallery__item">
          <div class="gallery__item-inner">
            <img src="/assets/images/slider/slide-3.webp" alt="">
            <div class="note gallery__note gallery__note--pos-3">
              <div class="note__content">Let people see you and your content</div>
              <span class="note__arrow note__arrow--right"></span>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="gallery__content">
    <h2 class="section-title">People LOVE Airglass</h2>
    <div class="gallery__content-items">
      <div class="gallery__content-item flex-center">
        <h2 class="secondary-title">
          “Airglass will deliver a complete,
          personalized desktop experience, giving people an immersive,
          natural, and secure communications tool for today’s
          modern meeting.”
          <span class="section-text">Phil Komarny CEO myLifeTrek</span>
        </h2>
        <figure>
          <img src="../../../assets/images/gallery/gallery-1.png" alt="">
        </figure>
      </div>
      <div class="gallery__content-item flex-center">
        <h2 class="secondary-title">
          “Airglass is the future of
          immersive tech and the expression of
          humanity and diversity on screen.”
          <span class="section-text">Justice P. Sikakane, Sr. Director of IT Hennepin Theatre Trust</span>
        </h2>
        <figure>
          <img src="../../../assets/images/gallery/gallery-2.png" alt="">
        </figure>
      </div>
      <div class="gallery__content-item flex-center">
        <h2 class="secondary-title">
          “Meetings are about to get more immersive,
          fluid, multilayered, contextual, interactive.
          And anchored to connected digital experience.”
          <span class="section-text">Dion Hinchcliffe VP and Principal Analyst  Constellation Research</span>
        </h2>
        <figure>
          <img src="../../../assets/images/gallery/gallery-3.png" alt="">
        </figure>
      </div>
      <div class="gallery__content-item flex-center">
        <h2 class="secondary-title">
          “Nothing beats being in person,
          but this is a close second and blows away
          anything that is on the market today.”
          <span class="section-text">Scott Schnaars Vice President of Americas Cloudinary</span>
        </h2>
        <figure>
          <img src="../../../assets/images/gallery/gallery-4.png" alt="">
        </figure>

      </div>
    </div>
  </div>
</div>
