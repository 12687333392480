import {Component, ViewChild} from '@angular/core';
import SwiperCore, {SwiperOptions, Autoplay} from 'swiper';
SwiperCore.use([Autoplay]);
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent {
  @ViewChild('swiperSlideShow') swiperSlideShow!: SwiperComponent;
  config: SwiperOptions = {};

  ngAfterViewInit(): void {
    this.config = {
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        300: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        }
      }
    };

    this.swiperSlideShow.swiperRef.autoplay.start();
  }

}
