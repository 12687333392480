<div class="purchase__block">
  <div class="purchase__logo">
    <img src="/assets/images/gift.svg" width="80" height="80" alt="">
  </div>
  <div class="purchase__descr section-text">
    <p>Purchase a gift code for a friend to use Airglass!</p>
  </div>
  <div class="purchase__actions">
    <a href="{{companyRedirectUrl}}/airglass/gift-code" target="_blank">
    <button
      mb-button
      mb-button-outline
      mb-button-text-large
      text="Purchase Gift Code"></button>
    </a>
  </div>
</div>
