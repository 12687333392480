import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


export enum DownloadUrl {
  MAC = 'https://download.mobeus.com/Airglass.pkg',
  WIN = 'https://downloads.mobeus.com/airglass/win_x64/Airglass-setup.exe'
}

@Component({
  selector: 'app-join-call',
  templateUrl: './join-call.component.html',
  styleUrls: ['./join-call.component.scss']
})
export class JoinCallComponent implements OnInit {
  private user = '';

  constructor(
    private activatedRoute: ActivatedRoute
  ){
  }

  ngOnInit(){
    this.user = this.activatedRoute.snapshot.params['user'];
  }

  public openApp(){
    location.href = environment.production ? `Airglass://${this.user}`: `${environment.envName}-Airglass://${this.user}`;
  }

  public openBrowser() {
    location.href = `${environment.browserLinkUrl}/?p=${this.user}`;
  }

  public download() {
    location.href = this.getDownloadUrl();
  }

  private getDownloadUrl(): string {
    const platform = (window as any).navigator?.userAgentData?.platform ?? window.navigator.platform;
    return /mac/i.test(platform) ? DownloadUrl.MAC : DownloadUrl.WIN;
  }
}
