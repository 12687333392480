<div class="gift-banner__section">
  <div class="container gift-banner__block">
    <h2 class="section-title gift-banner__title">
      {{demo ? 'Be understood. And memorable.' : 'Build deeper connections'}}
    </h2>
    <div *ngIf="!demo" class="gift-banner__descr section-text">
      <p>
        A new immersive and interactive channel
        <br>
        for customer engagement
      </p>
    </div>
    <!-- <div class="gift-banner__descr section-text">
      <p>
        14-day trial period. <br>
        $14.99/month or $150/year
      </p>
    </div> -->
    <div class="gift-banner__actions action-glow" *ngIf="!isMobileDevice && !isIpad">
      <button mb-button mb-button-text-large [isTertiary]="true" (click)="onAction()" text="Get Airglass"></button>
    </div>
  </div>
</div>