<div class="banner__section">
  <div class="container banner__inner">
    <div class="banner__logo">
      <img src="/assets/images/butterfly.webp" alt="">
    </div>
    <h2 class="section-title">A human connections company</h2>
    <div class="banner__descr section-text">
      <p>We create meaningful work for courageous visionaries, researchers, engineers & designers to organize around a mission to bringing full teleportation to life.</p>
      <a href="{{companyRedirectUrl}}/home">Learn more</a>
    </div>
  </div>
</div>
