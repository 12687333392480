import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AirglassComponent } from './components/airglass/airglass.component';
import { JoinCallComponent } from './components/join-call/join-call.component';

const routes: Routes = [
  {
    path: '',
    component: AirglassComponent
  },
  {
    matcher: (url) => {
      if (url.length === 1 && !isNaN(+url[0].path)) {
        return {
          consumed: url,
          posParams: {
            user: new UrlSegment(url[0].path, {})
          }
        };
      }
      return null;
    },
    component: JoinCallComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
