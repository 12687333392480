<div class="main">
  <app-hero></app-hero>
  <app-hero-slider></app-hero-slider>
  <app-features></app-features>
  <app-gift-banner></app-gift-banner>
  <app-demo-features></app-demo-features>
  <app-gallery-slider></app-gallery-slider>
  <app-gift-banner [demo]="true"></app-gift-banner>
  <app-banner></app-banner>
</div>
