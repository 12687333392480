<div class="features__section">
  <div class="features__container">
    <div class="features__list">

      <div class="features__item">
        <div class="features__item-info">
          <strong class="features__item-pre">AIRGLASS</strong>
          <h2 class="features__item-title">Showcase your personality and experiences inside any application</h2>
          <div class="features__item-descr">
            <p>Connect, share, and collaborate in a frictionless experience where you can be clearly understood. The revolutionary telepresence experience integrates live video and content mixed together.</p>
          </div>
        </div>
        <div class="features__item-media">
          <div class="features__item-thumb">
            <img src="/assets/images/features/feature-airglass.webp" alt="AIRGLASS">
          </div>
        </div>
      </div>

      <div class="features__item">
        <div class="features__item-info">
          <strong class="features__item-pre">BE SEEN</strong>
          <h2 class="features__item-title">Bring your unique self to your sales pitches</h2>
          <div class="features__item-descr">
            <p>Seeing you engaging with content can help the other attendees stay focused and on track. Your viewers gain a deeper understanding of the topic being presented and retain information for future use. </p>
          </div>
        </div>
        <div class="features__item-media">
          <div class="features__item-thumb">
            <img src="/assets/images/features/feature-sales.webp" alt="BE SEEN">
            <div class="note">
              <div class="note__content">Your audience pays more attention when <br>the screen forces everyone to focus</div>
            </div>
          </div>
        </div>
      </div>

      <div class="features__item">
        <div class="features__item-info">
          <strong class="features__item-pre">BE CONNECTED</strong>
          <h2 class="features__item-title">Make a lasting impression with recruiters</h2>
          <div class="features__item-descr">
            <p>Watching you and your content can help viewers better understand and retain the material. Being able to interact with your content allows the viewers to better understand complex concepts.</p>
          </div>
        </div>
        <div class="features__item-media">
          <div class="features__item-thumb">
            <img src="/assets/images/features/feature-resume.webp" alt="BE CONNECTED">
            <div class="note">
              <div class="note__content">Studies show we retain more when we <br>watch video compared to reading text</div>
            </div>
          </div>
        </div>
      </div>

      <div class="features__item">
        <div class="features__item-info">
          <strong class="features__item-pre">BE UNDERSTOOD</strong>
          <h2 class="features__item-title">Improve learning and skills development</h2>
          <div class="features__item-descr">
            <p>You’re speaking with the audience, not at the audience. Being in full screen helps you and your audience pay attention and stay curious. The mixture of you, your audience, and the content encourages the audience to remain engaged.</p>
          </div>
        </div>
        <div class="features__item-media">
          <div class="features__item-thumb">
            <img src="/assets/images/features/feature-salesforce.webp" alt="BE UNDERSTOOD">
            <div class="note">
              <div class="note__content">Having speakers come in and out of the screen <br>motivates people to be active participants</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
