<div class="footer">
  <div class="footer__inner">
    <!-- <div class="border-bottom">
      <app-purchase></app-purchase>
    </div> -->
    <app-socials></app-socials>
    <div class="footer__bottom">
      <div class="footer__logo">
        <img src="/assets/images/logo.png" width="180" height="89" alt="">
      </div>
      <div class="footer__copy">
        <p>&copy; {{ currentYear }} Mobeus Industries, Inc.&nbsp;&nbsp;<a href="{{companyRedirectUrl}}/terms" target="_blank">Terms</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a href="{{companyRedirectUrl}}/privacy" target="_blank">Privacy</a></p>
      </div>
    </div>
  </div>
</div>
