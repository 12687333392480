import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/base/header/header.component';
import { FooterComponent } from './components/base/footer/footer.component';
import { HeroComponent } from './components/main/hero/hero.component';
import { GallerySliderComponent } from './components/main/gallery-slider/gallery-slider.component';
import { FeaturesComponent } from './components/main/features/features.component';
import { BannerComponent } from './components/main/banner/banner.component';
import { DemoFeaturesComponent } from './components/main/demo-features/demo-features.component';
import { GiftBannerComponent } from './components/main/gift-banner/gift-banner.component';
import { PurchaseComponent } from './components/main/main/purchase/purchase.component';
import { MainComponent } from './components/main/main/main.component';
import { MbButtonModule, MbIconModule, MbIconSimpleModule } from '@mobeus/ui-kit';
import { SwiperModule } from 'swiper/angular';
import { SocialsComponent } from './components/socials/socials.component';
import { HeroSliderComponent } from './components/main/hero-slider/hero-slider.component';
import { AppRoutingModule } from './app.routing.module';
import { AirglassComponent } from './components/airglass/airglass.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    AirglassComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    GallerySliderComponent,
    HeroSliderComponent,
    FeaturesComponent,
    BannerComponent,
    DemoFeaturesComponent,
    GiftBannerComponent,
    PurchaseComponent,
    MainComponent,
    SocialsComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    MbButtonModule,
    SwiperModule,
    MbIconModule,
    MbIconSimpleModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
